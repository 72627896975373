<template>
  <div class="container">
    <div class="page-not-found">
      <div class="pnf-main">
        <div class="cover">
          <el-image
            class="fake-img"
            :src="ossUrl + '/images/permission-denied/permission-denied.png'"
            fit="scale-down"
            lazy
          ></el-image>
        </div>
        <div class="descriptions">
          <p class="fake-p">没有权限，拒绝访问！</p>
          <p class="fake-p">Permission Denied</p>
        </div>
        <div class="count-down">即将跳转首页，{{count_down}}s</div>
        <ul class="control">
          <li class="control-item">
            <el-button
              class="fake-btn"
              @click="skipToPageFun(true)"
            >
              <span>返回上一页</span>
            </el-button>
          </li>
          <li class="control-item">
            <el-button
              class="fake-btn"
              @click="skipToPageFun()"
            >
              <span>返回首页</span>
            </el-button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 允许组件模板递归地调用自身
  name: 'PageNotFound',
  // 声明一组可用于组件实例中的组件
  components: {

  },
  // 一个用于从父组件接收数据的数组或对象
  props: {

  },
  // 该函数返回组件实例的 data 对象
  data () {
    return {
      ossUrl: this.GLOBAL.ossUrl,
      count_down: 3,
      timer: null,
    }
  },
  // 计算属性：
  computed: {

  },
  // 钩子函数--侦听data变动：
  watch: {

  },
  // 在实例创建完成后被立即同步调用
  methods: {
    /**
     * 跳转页面函数
     */
    skipToPageFun(goback) {
      if(goback) {
        this.$router.go(-2);
        return;
      }

      // 跳转路由
      this.myRouterPush({
        path: '/',
        query: {
          // data: encodeURIComponent(JSON.stringify(skip_qurey)),
        }
      });
    },
    /**
     * 倒计时
     */
    countDownFun() {
      const _this = this;
      return new Promise((resolve) => {
        let end_time = 5;
        this.timer = setInterval(() => {
          if(end_time <= 0) {
            // 自定义清除定时器
            _this._clearIntervalFun();
            this.count_down = '';
            resolve();
            return;
          }
          end_time--;
          this.count_down = end_time;
        },1000)
      })
    },
     /**
     * 自定义清除定时器
     */
    _clearIntervalFun(){
      if(this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
  },
  // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
  created() {

  },
  // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
  mounted() {
    // 倒计时
    this.countDownFun().then(() => {
      // 跳转页面函数
      this.skipToPageFun(false);
    });
  },
  beforeCreate() { // 创建前

  },
  beforeMount() { // 挂载前

  },
  beforeUpdate() { // 更新前

  },
  updated() { // 更新后

  },
  beforeDestroy() { // 销毁前
    // 自定义清除定时器
    this._clearIntervalFun();
  },
  destroyed() { // 销毁后

  },
  activated() { // 缓存保持，需要由 keep-alive 触发

  },
}
</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
.container {
  box-sizing: border-box;
}

.page-not-found {
  margin: 100px auto;
  .pnf-main {
    .cover {
      margin: 0 auto;
      width: 330px;
      height: 310px;
      .fake-img {
        width: 100%;
        height: 100%;
      }
    }
    .descriptions {
      text-align: center;
      .fake-p {
        margin-bottom: 10px;
        font-size: 16px;
        color: #666;
        &:first-of-type {
          font-size: 22px;
          color: #444433;
        }
      }
    }
    .count-down {
      margin-top: 50px;
      text-align: center;
      font-size: 22px;
      color: #444433;
    }
    .control {
      display: flex;
      justify-content: center;
      .control-item {
        width: 200px;
        margin: 60px 20px;
        &:last-of-type {
          .fake-btn {
            border: 1px solid #6bc4ff;
            background: #fff;
            color: #6bc4ff;
          }
        }
        .fake-btn {
          width: 100%;
          height: 48px;
          font-size: 18px;
          color: #fff;
          border: 1px solid $common-number1-color;
          background: $common-number1-color;
          outline: none;
          &.el-button:hover,&.el-button:focus,&.el-button:active {
            color: none;
            border-color: none;
            background-color: none;
          }
        }
      }
    }
  }
}
</style>
